<template>
  <div>
    <el-row style="margin-left: 10px">
      <el-button type="primary" @click="brandAddOpen">添加品牌</el-button>
      <el-button type="primary" @click="brandGradeOpen">品牌等级管理</el-button>
    </el-row>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-table
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :row-style="{height:30+'px'}"
          :cell-style="{padding:5+'px'}"
          :data="brandArray"
          border
          max-height="650"
          style="width: 100%">
        <el-table-column label="品牌名称" align="center">
          <template #default="scope">
            <span>{{scope.row.brandName}}</span>&nbsp
            <el-tag v-if="scope.row.isAuth === 1"
                    effect="dark"
                    type="warning"
                    size="mini">
              ivory严选
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="gradeName" label="品牌等级" align="center"></el-table-column>
        <el-table-column label="可筛可选" align="center">
          <template #default="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isDisplay"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="!show(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否激活" align="center">
          <template #default="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="!show(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button-group>
              <el-button size="mini" type="primary" v-if="show(scope.row)" @click="brandEditOpen(scope.row)">修改</el-button>
              <el-button size="mini" type="warning" v-if="tenantCrop === '2a31c23a-c178-441614928053489'" @click="auth(scope.row)">一键认证</el-button>
              <el-button size="mini" type="danger" v-if="tenantCrop === '2a31c23a-c178-441614928053489'" @click="quitAuth(scope.row)">取消认证</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <brand-add v-if="brandAddState" :state="brandAddState" @close="brandAddClose"></brand-add>
    <brand-grade-list v-if="brandGradeState" :state="brandGradeState" @close="brandGradeClose"></brand-grade-list>
    <brand-edit v-if="brandEditState" :state="brandEditState" :brand="brand" @close="brandEditClose"></brand-edit>
  </div>
</template>

<script>
import brandAdd from "@/pages/style/brand/brand-add"
import brandGradeList from "@/pages/style/brand/brand-grade-list"
import brandEdit from "@/pages/style/brand/brand-edit"
export default {
  name: "brand-list",
  created() {
    this.pageInit()
  },
  components: {
    brandAdd,
    brandGradeList,
    brandEdit
  },
  data() {
    return {
      brandArray: [],
      tenantCrop: localStorage.getItem("tenantCrop"),
      total: 0,
      page: 1,
      limit: 100,
      brandAddState: false,
      brandGradeState: false,
      brandEditState: false,
      brand: {},
    }
  },
  computed: {
    show() {
      return function (val) {
        return this.tenantCrop === '2a31c23a-c178-441614928053489' ? true : val.isAuth !== 1 ? true : false
      }
    },
  },
  methods: {
    pageInit() {
      this.queryBrandList()
    },
    // 查询品牌列表
    queryBrandList() {
      this.$axios({
        method: "GET",
        url: "/styleBrand/brandList",
        params: {
          page:this.page,
          limit:this.limit,
          tenantCrop: this.tenantCrop
        }
      }).then(response => {
        this.brandArray = response.data.data.list
        this.total = response.data.data.total
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryBrandList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryBrandList()
    },
    isChange(val) {
      this.$axios({
        method: "POST",
        url: "/styleBrand/updateStyleBrand",
        params: {
          id: val.id,
          isValid: val.isValid,
          isDisplay: val.isDisplay,
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        if (flag) {
          this.queryBrandList()
        }
      })
    },
    auth(val) {
      if (val.isAuth === 1) {
        this.$message({
          showClose: true,
          message: "该品牌已认证，请勿重复认证",
          type: 'warning',
          duration: 1500,
        })
        return
      }
      this.$axios({
        method: "POST",
        url: "/styleBrand/updateStyleBrand",
        params: {
          id: val.id,
          isAuth: 1,
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "认证成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.queryBrandList()
      })
    },
    quitAuth(val) {
      if (val.isAuth === 0) {
        this.$message({
          showClose: true,
          message: "该品牌暂未认证，请勿点击取消认证",
          type: 'warning',
          duration: 1500,
        })
        return
      }
      this.$axios({
        method: "POST",
        url:"/styleBrand/updateStyleBrand",
        params: {
          "id": val.id,
          "isAuth":0
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "取消认证成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.queryBrandList()
      })
    },
    brandAddOpen() {
      this.brandAddState = true
    },
    brandAddClose() {
      this.brandAddState = false
      this.queryBrandList()
    },
    brandGradeOpen() {
      this.brandGradeState = true
    },
    brandGradeClose() {
      this.brandGradeState = false
    },
    brandEditOpen(val) {
      this.brand = val
      this.brandEditState =true
    },
    brandEditClose() {
      this.brandEditState = false
      this.queryBrandList()
    }
  }
}
</script>

<style scoped>

</style>