<template>
  <el-dialog
      title="添加品牌"
      width="30%"
      :visible.sync="states"
      :modal-append-to-body="false"
      @close="close">
      <el-form label-width="100px" :model="form" :rules="rules" ref="form">
        <el-row>
          <el-col :span="20" :offset="1">
            <el-form-item label="品牌名称" prop="brandName">
              <el-input v-model="form.brandName" placeholder="请填写品牌名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" :offset="1">
            <el-form-item label="品牌等级" prop="brandGrade">
              <el-select v-model="form.brandGrade" placeholder="请选择" style="width: 100%">
                <el-option
                    v-for="item in brandGradeArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "brand-add",
  created() {
    this.pageInit()
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: true,
    }
  },
  data() {
    return {
      states: this.state,
      form: {
        brandName: "",
        brandGrade: "",
      },
      rules: {
        brandName: [{required: true, message: '请填写品牌名称', trigger: 'blur'}],
        brandGrade: [{required: true, message: '请选择品牌等级', trigger: 'change'}],
      },
      brandGradeArray: [],
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryBrandGradeIds().then(response => {
        this.brandGradeArray = JSON.parse(response.data.data)
      })
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: 'POST',
            url: '/styleBrand/saveStyleBrand',
            params: {
              ...this.form,
              tenantCrop: localStorage.getItem("tenantCrop")
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? "品牌添加成功" :response.data.msg,
              type: flag? 'success' :'error',
              duration: 1000,
            })
            if (flag) {
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>